import React, { useState, Fragment, useContext, useRef, useEffect } from "react"
import * as styles from "./write.module.css"
import Layout from "components/common/layout.js"
import modalContext from "context/modalContext"
import SearchModal from "components/tools/modalComponents/searchModal.js"
// 이미지
import storyRegist from "images/storyRegist.png"
//
import MenuContainer from "components/tools/menu/Container.js"
import LessonMenu from "components/tools/menu/lesson.js"
//
import FileUploadBox from "components/tools/fileUploadBox.js"
import TextEditor from "components/tools/textEditor"
import CheckBox from "components/tools/checkBox.js"
import { getFormData, arrayEdit } from "utils"
import api from "apis"
import { lessonMenu } from "utils/data"
import queryString from "query-string"
import { navigate } from "gatsby"
import _ from "lodash"
const Write = props => {
  const keys = ["course", "curriculum", "kind"]
  const [post, setPost] = useState({})
  const [checkedList, setCheckedList] = useState({
    course: [],
    curriculum: [],
    kind: [],
  })
  const [storyInfo, setStoryInfo] = useState({
    story_no: 0,
    thumb: null,
  })
  const [fileList, setFileList] = useState([])
  const [removeList, setRemoveList] = useState([])
  const [prevList, setPrevList] = useState([])
  const [nanum_comment, setNanum_comment] = useState(true)

  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const titleInput = useRef(null)

  //textEditor
  const editorRef = useRef(null)

  //error
  const errMsgRef = useRef({
    title: null,
    course: null,
    curriculum: null,
    kind: null,
    story: null,
  })

  const { number } = queryString.parse(props.location.search)

  useEffect(() => {
    ;(async () => {
      if (number) {
        const post = await api.lessonDetail_test(number)
        if (post) setPost(post)

        setCheckedList(
          keys.reduce((init, key) => ({ ...init, [key]: post[key] }), {})
        )
        setStoryInfo({
          story_no: post.story.number,
          thumb: post.story.img,
        })
        setNanum_comment(post.isComment)

        const fileList = await Promise.all(
          post.fileList.map(async file => await file.setFile())
        )

        setFileList(fileList)
        prevList.current = fileList
      }
    })()
  }, [])

  const onStoryRegist = () => {
    setmodalOpen(true)
    setModalMain(
      <SearchModal
        onClose={() => setmodalOpen(false)}
        setStoryInfo={setStoryInfo}
      />
    )
  }

  const onRegist = _.debounce(async () => {
    const invalidTitle = checkError(
      titleInput.current.value,
      errMsgRef.current.title
    )
    const invalidCourse = checkError(
      checkedList.course.length,
      errMsgRef.current.course
    )
    const invalidCurriculum = checkError(
      checkedList.curriculum.length,
      errMsgRef.current.curriculum
    )
    const invalidKind = checkError(
      checkedList.kind.length,
      errMsgRef.current.kind
    )
    const invalidStory = checkError(storyInfo.story_no, errMsgRef.current.story)
    const invalidEdit = checkErrorEdit(editorRef.current)
    if (
      invalidTitle ||
      invalidCourse ||
      invalidCurriculum ||
      invalidKind ||
      invalidStory ||
      invalidEdit
    ) {
      return
    }

    const formdata = getFormData({
      nanum_title: titleInput.current.value,
      nanum_taggings: "수업나눔#수업#초등#중등#고등#성인",
      nanum_story: storyInfo.story_no,
      nanum_body: editorRef.current.getEditorContents(),
      nanum_comment,
    })
    keys.forEach(key => {
      const contents = Array.from(lessonMenu.get(key).contents.keys())
      formdata.append(
        `nanum_${key}`,
        arrayEdit(checkedList[key].map(elem => contents.indexOf(elem) + 1))
      )
    })
    let result = false
    console.log(fileList)
    if (post.nanum_no && post.isMine) {
      formdata.append("nanum_number", post.nanum_no)
      //파일 수정,삭제시 api promise.all로 적용 필요

      //삭제
      await Promise.all(
        removeList.map(
          async file =>
            await api.eduAttachDel({
              body: {
                record_number: post.nanum_no,
                attach_number: file.file_no,
              },
            })
        )
      )

      //등록
      await Promise.all(
        fileList
          .filter(file => !(file.file_no && file.uuid))
          .map(async file => {
            const formData = getFormData({
              record_number: post.nanum_no,
              store_number: 0,
              data_attach: file,
            })

            return await api.eduAttachAdd({
              body: formData,
            })
          })
      )

      result = await api.lessonMod({ body: formdata })
    } else {
      fileList.forEach(file => formdata.append("data_attach", file))
      result = await api.lessonWrite({ body: formdata })
    }
    if (result) {
      navigate(`/class/lesson/detail?number=${result}`)
    } else {
      alert("잠시후 다시 시도해주세요")
    }
  }, 2000)

  return (
    <Layout>
      <div className={styles.con}>
        <div className={styles.title}>
          <div>수업나눔 작성하기</div>
          <div className={`greenBtn ${styles.btnPosition}`} onClick={onRegist}>
            등록
          </div>
        </div>
        <hr />
        <div className={styles.inputLayout}>
          <div className={styles.titleInput}>
            <div>제목</div>
            <input
              type="text"
              ref={titleInput}
              defaultValue={post?.title}
              className={styles.typingTitle}
            />
          </div>

          <div
            className={`errMsg ${styles.titleErr}`}
            ref={c => (errMsgRef.current["title"] = c)}
          />

          <div className={styles.menu}>
            {keys.map((key, i) => (
              <Fragment key={i}>
                <MenuContainer
                  separ={key}
                  checkedList={checkedList}
                  setCheckedList={setCheckedList}
                  child={
                    <LessonMenu
                      errMsgRef={errMsgRef}
                      checkedList={checkedList}
                    />
                  }
                />
              </Fragment>
            ))}
          </div>

          <div className={styles.storyRegist}>
            <div>스토리 등록하기</div>
            {storyInfo.thumb ? (
              <img src={storyInfo.thumb} alt="" />
            ) : (
              <div onClick={onStoryRegist}>
                <img src={storyRegist} alt="" />
              </div>
            )}
          </div>
        </div>

        {storyInfo.story_no ? (
          <div className={styles.modify} onClick={onStoryRegist}>
            수정
          </div>
        ) : (
          <div style={{ height: "33px" }}></div>
        )}

        <div
          style={{ marginBottom: "33px" }}
          className="errMsg"
          ref={c => (errMsgRef.current["story"] = c)}
        />

        <div>
          <TextEditor editorRef={editorRef} value={post.bodyText} />
        </div>
        <div>
          <FileUploadBox
            fileList={fileList}
            setFileList={setFileList}
            setRemoveList={setRemoveList}
          />
        </div>
        <div className={styles.checker}>
          <div>댓글허용</div>
          <CheckBox
            id={"nanum_comment"}
            className={"toggleBtn"}
            onChange={({ target: { checked } }) => setNanum_comment(checked)}
            checked={nanum_comment}
          />
        </div>
        <div className={`greenBtn ${styles.btnPosition}`} onClick={onRegist}>
          등록
        </div>
      </div>
    </Layout>
  )
}

export default Write

const errMsg = `<span style="color:red;">내용을 입력하세요.</span><p>저작권을 침해하는 경우 선생님께서 작성하신 소중한 수업나눔이 삭제될 수 있습니다. 저작권 책임은 작성자에게 있습니다.</p>`

const checkError = (flag, ref) => {
  const errMsg = "필수항목 입니다."

  if (!flag) {
    ref.textContent = errMsg
    ref.style = "display: block"
    return true
  } else {
    ref.style = "display: none"
    return false
  }
}
const checkErrorEdit = current => {
  const textContent = current.getEditorContents()
  if (!textContent || textContent === errMsg) {
    current.setEditorContents(current.editor, errMsg)
    return true
  } else {
    return false
  }
}

// extracted by mini-css-extract-plugin
export var con = "searchModal-module--con--3212N";
export var header = "searchModal-module--header--2yz5O";
export var checkMenu = "searchModal-module--checkMenu--28Rdf";
export var searchOption = "searchModal-module--searchOption--3tpk6";
export var resultBox = "searchModal-module--resultBox--38hm6";
export var resultText = "searchModal-module--resultText--8L5sH";
export var result = "searchModal-module--result--3Ca2V";
export var pg = "searchModal-module--pg--2AAiR";
export var btn = "searchModal-module--btn--2O7gI";
export var headerCon = "searchModal-module--headerCon--15UxK";
export var colon = "searchModal-module--colon--39BmL";
import React from "react"
import * as styles from "./category.module.css"
import CheckBox from "components/tools/checkBox.js"
import { storyCategory } from "utils/data"
const Category = props => {
  const { sub } = storyCategory
  return (
    <div className={styles.con}>
      <div className={styles.checkMgt}>
        <div>카테고리 선택</div>
      </div>
      <div className={styles.checkList}>
        {Array.from(sub).map(([key, val], i) => (
          <div key={`${key}_${props.separ}_${i}`}>
            <CheckBox
              id={`${props.separ}_${key}`}
              data={key}
              name={`${props.separ}`}
              className={"squareCheckBox"}
              onClick={props.onCheck}
            />
            <div>{val}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category

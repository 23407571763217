import React, { useCallback, useRef, useEffect, useState, useMemo } from "react"
// import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./styles.css"
import ImageResize from "@looop/quill-image-resize-module-react"
// import VideoResize from "quill-video-resize-module2"
import api from "apis"
import { getThumb } from "utils"

const Editor = props => {
  console.log(props)
  const [ReactQuill, setReactQuill] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { default: ReactQuill } = await import("react-quill")

      ReactQuill.Quill.register("modules/ImageResize", ImageResize)
      // ReactQuill.Quill.register("modules/VideoResize", VideoResize)

      const Size = ReactQuill.Quill.import("formats/size")
      Size.whitelist = ["10pt", "12pt", "14pt", "15pt", "18pt", "24pt", "32pt"]
      ReactQuill.Quill.register(Size, true)

      const Align = ReactQuill.Quill.import("formats/align")
      Align.whitelist = ["", "center", "right"]
      ReactQuill.Quill.register(Align, true)

      // Add fonts to whitelist and register them
      const Font = ReactQuill.Quill.import("formats/font")
      Font.whitelist = [
        "arial",
        "comic-sans",
        "courier-new",
        "georgia",
        "helvetica",
        "lucida",
      ]
      ReactQuill.Quill.register(Font, true)
      //
      const Video = ReactQuill.Quill.import("formats/video")

      // ReactQuill.Quill.register("formats/video", Video)

      ReactQuill.Quill.register(Video, true)

      setReactQuill(
        <>
          <CustomToolbar />
          <ReactQuill
            style={{ height: "600px" }}
            value={props.value || ""}
            // placeholder={"내용을 입력해주세요."}
            ref={props.editorRef}
            modules={modules}
            formats={formats}
            placeholder={`
수업나눔에 게시할 자료에 저작권이나 초상권에 위배되는 내용, 이미지
 등이 있을 경우 저작권 위반 빛 불법 유통에 해당됩니다.
 첨부하는 자료에 사용하는 내용과 이미지를 제작자의 동의 없이
 사용해도 되는지 확인하시기 바랍니다.
 저작권 위배로 인한 법적 제재는 피치서가와 무관하며 게시한 회원이
 책임을 져야합니다.
 저작권 보호와 안전한 수업나눔을 위해 올바른 이용 부탁드립니다. `}
          />
        </>
      )
    })()
  }, [props.value])

  const imageHandler = () => {
    // input file tag 생성
    console.log(props.editorRef.current)
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    document.body.appendChild(input)
    input.click()
    input.onchange = async ({ target }) => {
      const [file] = target.files
      const formData = new FormData()
      formData.append("data_pub", file)

      const file_uuid = await api.addStorage({ body: formData }) //생성요함
      console.log(file_uuid)

      if (file_uuid) {
        const range = props.editorRef.current.getEditorSelection()

        props.editorRef.current
          .getEditor()
          .insertEmbed(range.index, "image", getThumb(file_uuid))
        props.editorRef.current.getEditor().setSelection(range.index + 1)
        document.body.querySelector(":scope > input").remove()
      }
    }
  }

  //폰트 사이즈 추가
  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: { image: imageHandler },
      },
      ImageResize: { modules: ["Resize"] },
      // VideoResize: {
      //   modules: ["Resize", "DisplaySize", "Toolbar"],
      //   tagName: "iframe", // iframe | video
      // },
    }),
    []
  )

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
  ]

  return <div className="text-editor">{ReactQuill}</div>
}

export default Editor

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-font" defaultValue={"arial"}>
      <option value="arial">Arial</option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>
    <select className="ql-size" defaultValue={"18pt"}>
      <option value="10pt">10pt</option>
      <option value="12pt">12pt</option>
      <option value="14pt">14pt</option>
      <option value="15pt">15pt</option>
      <option value="18pt">18pt</option>
      <option value="24pt">24pt</option>
      <option value="32pt">32pt</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <select className="ql-color" defaultValue={""}>
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option></option>
    </select>
    {/* <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button> */}
    <select className="ql-align" defaultValue={""}>
      <option selected></option>
      <option value="center"></option>
      <option value="right"></option>
      {/* <option value="justify"></option> */}
    </select>
    {/* <button className="ql-link"></button> */}
    <button className="ql-image"></button>
    {/* <button className="ql-video"></button> */}
  </div>
)

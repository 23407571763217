import React, { useState, useEffect, useCallback } from "react"
import * as styles from "./searchModal.module.css"
// import SearchContainer from "containers/searchContainer"
import Search from "components/tools/search/search.js"
import Pagenation from "components/tools/pagination.js"
import modalClose from "images/modalClose.png"
import MenuContainer from "components/tools/menu/Container.js"
import Topic from "components/tools/menu/topic.js"
import Category from "components/tools/menu/category.js"
import upIcon from "images/upIcon.png"
import downIcon from "images/downIcon.png"
//
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { getSearchListAsync, resetOption } from "store/modules/searchReducer.js"
import Story from "components/story/story"
import { getThumb } from "utils"
import { useToggle } from "hooks"

const SearchModal = props => {
  const dispatch = useDispatch()
  const getStoryList = useCallback(
    (page, take, order, type, tagNumber, keyword, checkedList) =>
      dispatch(
        getSearchListAsync(
          page,
          take,
          order,
          type,
          tagNumber,
          keyword,
          checkedList
        )
      ),
    [dispatch]
  )

  const [currentPage, setCurrentPage] = useState(1)
  const pagePostSize = 8
  const { tag, keyword, toggle } = useSelector(
    ({ searchReducer: { option } }) => option,
    shallowEqual
  )

  const { storyList, stotyTotal } = useSelector(
    ({ searchReducer: { story } }) => story,
    shallowEqual
  )

  const [category, setCategory] = useState(null)
  const [topic, setTopic] = useState([])

  useEffect(() => {
    ;(async () => {
      const order = 1
      const type = "story"

      getStoryList(
        currentPage,
        pagePostSize,
        order,
        type,
        tag,
        keyword,
        checkedList
      )
    })()
  }, [toggle])

  const menuList = [
    {
      keyword: "topic",
      component: <Topic setTopic={setTopic} />,
    },
    {
      keyword: "subCategory",
      component: <Category setCategory={setCategory} />,
    },
  ]

  const [btnState, setbtnState] = useState({
    text: "검색옵션 열기",
    img: downIcon,
  })

  const [checkedList, setCheckedList] = useState(
    menuList.reduce(
      (initial, { keyword }) => ({
        ...initial,
        [keyword]: [],
      }),
      {}
    )
  )

  const [currentImg, setCurrentImg] = useState(null)
  const [selectStory, setSelectStory] = useState({
    story_no: 0,
    thumb: null,
  })

  const onSelect = ({ currentTarget }, story_no, thumb) => {
    setSelectStory({
      story_no,
      thumb: getThumb(thumb),
    })

    const style =
      "grayscale(100%) brightness(55%) sepia(50%) hue-rotate(-39deg) saturate(600%) contrast(0.8) opacity(80%)"
    const img = currentTarget.querySelector("img")

    if (currentImg) currentImg.style.filter = ""
    img.style.filter = style
    setCurrentImg(img)
  }

  const onSearchOption = () => {
    const [checkMenu] = document.getElementsByClassName(`${styles.checkMenu}`)
    optionSwitch(checkMenu)
    setbtnState(setState(btnState))
  }

  const onRegist = () => {
    props.setStoryInfo(selectStory)
    props.onClose()
  }

  return (
    <div className={styles.con}>
      <div className={styles.headerCon}>
        <div className={styles.header}>
          <div>
            <div>스토리 검색</div>
            <div className={styles.colon}>
              <div style={{ paddingTop: "10px" }}>.</div>
              <div>.</div>
            </div>
            <Search placeholder={"찾고 싶은 스토리를 단어로 입력하세요."} />
          </div>
          <img
            src={modalClose}
            alt=""
            onClick={() => {
              dispatch(resetOption())
              props.onClose()
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <div className={styles.checkMenu}>
          {menuList.map((menu, i) => (
            <MenuContainer
              key={`${menu.keyword}_key_${i}`}
              separ={menu.keyword}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              isOneCheck={i === 1 ? true : false}
              child={menu.component}
            />
          ))}
        </div>

        <div className={styles.searchOption} onClick={onSearchOption}>
          <hr />
          <div>
            <div>{btnState.text}</div>
            <img src={btnState.img} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.resultBox}>
        <div className={styles.result}>
          {storyList.map(post => (
            <Story
              registerTime={post.publishTime}
              key={post.story_no}
              story_no={post.story_no}
              title={post.title}
              introText={post.introText}
              thumb_title_uuid={post.thumb_title_uuid}
              cateMain={post.cateMain}
              cateSub={post.cateSub}
              Like={post.Like}
              free={post.free}
              onClick={e => onSelect(e, post.story_no, post.thumb_title_uuid)}
              likeImg={true}
            />
          ))}
        </div>
      </div>
      <div className={styles.pg}>
        <Pagenation
          total={stotyTotal}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={`greenBtn ${styles.btn}`} onClick={onRegist}>
        등록하기
      </div>
    </div>
  )
}

export default SearchModal

const optionSwitch = checkMenu => {
  const display = checkMenu.style.display
  const resultDiv = document.getElementsByClassName(`${styles.resultBox}`)

  console.log(resultDiv[0])

  const checkMenuDisplay = display === "none" || display === ""

  if (checkMenuDisplay) {
    checkMenu.style.display = "block"

    resultDiv[0].style.marginTop = "406px"
  } else {
    checkMenu.style.display = "none"
    resultDiv[0].style.marginTop = "150px"
  }

  // display === "none" || display === ""
  //   ? (checkMenu.style.display = "block")
  //   : (checkMenu.style.display = "none")
}

const setState = state => ({
  text: state.text === "검색옵션 열기" ? "검색옵션 닫기" : "검색옵션 열기",
  img: state.img === upIcon ? downIcon : upIcon,
})

// extracted by mini-css-extract-plugin
export var con = "write-module--con--1GfpG";
export var title = "write-module--title--3DkOv";
export var btnPosition = "write-module--btnPosition--sJ2gL";
export var typingTitle = "write-module--typingTitle--YMFvW";
export var storyRegist = "write-module--storyRegist--2XMkJ";
export var titleInput = "write-module--titleInput--3Po45";
export var menu = "write-module--menu--1qKTR";
export var checker = "write-module--checker--1LeOh";
export var modify = "write-module--modify--2wE4u";
export var titleErr = "write-module--titleErr--Lz8mO";
export var inputLayout = "write-module--inputLayout--2OvBX";
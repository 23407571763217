import React from "react"
import { topics } from "utils/data"
import * as styles from "./topic.module.css"
import CheckBox from "components/tools/checkBox.js"
const Topic = props => {
  return (
    <div className={styles.con}>
      <div className={styles.checkMgt}>
        <CheckBox
          id={props.checkMgtId}
          className={"squareCheckBox"}
          onClick={props.onCheckMgt}
          // checked={key.startsWith("all") ? true : null}
        />
        <div>관심주제 전체</div>
      </div>
      <div className={styles.checkList}>
        {Array.from(topics).map(([key, { contents }], i) => (
          <div key={`${key}_${props.separ}_${i}`}>
            <CheckBox
              id={`${props.separ}_${key}`}
              data={key}
              name={`${props.separ}`}
              className={"squareCheckBox"}
              onClick={props.onCheck}
            />
            <div>{contents}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Topic
